import ApplicationController from './application_controller';
import {loadStripe} from '@stripe/stripe-js';

export default class extends ApplicationController {
  static values = {
    stripeClientSecret: String,
    stripePublicKey: String
  }


  async connect() {
    const stripe = await loadStripe(this.stripePublicKeyValue);

    this.checkout = await stripe.initEmbeddedCheckout({
      clientSecret: this.stripeClientSecretValue
    })
    this.checkout.mount(this.element)
  }

  disconnect() {
    this.checkout.destroy()
  }
}
